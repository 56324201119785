/**
 * role
 * 角色
 */

 import request from '@/utils/request'

 // 编辑
 export const roleUpdateRole = (data) => {
     return request({
         method: 'post',
         url: '/role/update_role',
         data
     })
 }

 